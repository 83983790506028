import React, {Component} from "react";
import {connect} from "react-redux";
import {RETRIEVED} from "../../session/util/progressType";
import RetrieveSession from "../../session/container/RetrieveSession";
import SearchOrder from "./SearchOrder";
import {ORDER_ERROR, ORDER_START} from "../util/progressState";
import SaveOrder from "./SaveOrder";
import {MDBContainer, MDBRow} from "mdbreact";
import {updateMainPageSuccess} from "../../shop/store/shopAction";

export class Consumer extends Component {

    
    componentDidMount() {
        this.props.updateMainPageSuccess();
    }

    render() {
        return (

            <div className="consumer" data-test="consumer-div">

                <MDBContainer>
                    <MDBRow className="d-flex flex-row justify-content-center row">
                        <RetrieveSession/>
                    </MDBRow>

                    {(RETRIEVED === this.props.sessionProgress) &&
                    <MDBRow className="d-flex flex-row justify-content-center row">
                        <SearchOrder/>
                    </MDBRow>
                    }

                    {((ORDER_START !== this.props.orderProgressState) && (ORDER_ERROR !== this.props.orderProgressState)) &&
                    <MDBRow className="d-flex flex-row justify-content-center row">
                        <SaveOrder/>
                    </MDBRow>
                    }

                </MDBContainer>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        sessionProgress: state.session.progress,
        orderProgressState: state.orderReturn.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMainPageSuccess: () => dispatch(updateMainPageSuccess())
    };
};

const ViewConsumer = connect(mapStateToProps, mapDispatchToProps)(Consumer);

export default ViewConsumer;