import React, {Component} from 'react';
import './Input.css';

class EmailInput extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChange(this.props.field, event.target.value);
    }

    render() {
        const inpValue = this.props.value;
        const inpHolder = this.props.holder;
        return(
            <div data-test="email-div" className={this.props.inptype}>
                <input type="email"
                       size={this.props.size}
                       maxLength={this.props.size}
                       placeholder={inpHolder}
                       value={inpValue}
                       onChange={this.handleChange}
                       disabled={this.props.disabled}/>
            </div>
        );
    }
}

export default EmailInput;