import {SESSION_CLEAN, SESSION_FAILURE, SESSION_REQUEST, SESSION_SUCCESS} from "../../../store/actionType";

export const startSessionRequest = () => {
    return {
        type: SESSION_REQUEST
    };
};

export const retrievedSession = (sessionData) => {
    return {
        type: SESSION_SUCCESS,
        data: sessionData
    };
};

export const failedSession = (error) => {
    return {
        type: SESSION_FAILURE,
        error: error
    };
};

export const sessionClean = () => {
    return {
        type: SESSION_CLEAN
    };
}