import React, {Component} from 'react';
import PropTypes from 'prop-types';
import intl from "react-intl-universal";
import {MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import {connect} from "react-redux";
import {getAllShop, startGetLogin} from "../../shop/store/shopService";
import Shops from "../../shop/component/Shops";
import './Home.css'
import {Redirect} from "react-router";

export class HomeTest extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        allShops: this.props.allShops,
        searchShops: this.props.allShops
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.allShops.length === 0) {
            return {
                allShops: nextProps.allShops,
                searchShops: nextProps.allShops,
            };
        }
        return null;
    }

    componentDidMount() {
        this.props.getAllShop();
    }

    handlerLogin = (shop) => {
        this.props.getLogin(shop);
    }

    haldlerSearchShop = (e) => { // X X X

        const value = e.target.value;
        const all = this.state.allShops;

        if (value.length > 2) {
            const target = value.toUpperCase();

            let myShops = all.filter((obj) => {
                return this.checkSearch(obj, target);
            });
            this.setState({
                    searchShops: myShops
                }
            )
        } else {
            this.setState({
                    searchShops: all
                }
            )
        }
    };

    checkSearch = (shop, value) => {
        let isMatch = false;

        const shopName = shop.name.toUpperCase();

        if (shopName.includes(value)) {
            isMatch = true;
        }
        return isMatch;

    };


    render() {

        let login = null;
        if (this.props.status === "SUCCESS") {
            return <Redirect push to={"/main"}/>        }

        let err = null;
        if (this.props.status === "ERROR") {
            err = (
                <div>
                    <div className="errorFont">{intl.get('home.errorMsg')}</div>
                </div>
            )
        }

        return (
            <div data-test="home-div">
                {login}
                <MDBContainer>
                    <MDBRow className="d-flex flex-row ">
                    </MDBRow>
                    <MDBRow className="d-flex flex-row justify-content-center row">
                        <div className="input-group md-form form-sm form-1 pl-0 w-50">
                            <div className="input-group-prepend">
                            <span className="input-group-text green lighten-3" id="basic-text1">
                            <MDBIcon icon="search" className="text-grey"/>
                             </span>
                            </div>
                            <input className="form-control form-control-sm  w-50"
                                   type="text"
                                   placeholder={intl.get('home.searchPlaceholder')}
                                   onChange={evt => this.haldlerSearchShop(evt)}
                                   aria-label={intl.get('home.searchPlaceholder')}
                                   style={{width: "6.5rem"}}
                            />
                        </div>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            &nbsp;
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <Shops
                                allShops={this.state.searchShops}
                                handlerLogin={this.handlerLogin}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="d-flex flex-row justify-content-center row">
                        {err}
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

HomeTest.propTypes = {
    allShops: PropTypes.array.isRequired,
    getAllShop: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        allShops: state.shops.shops,
        status: state.shops.status,
        id: state.shops.id,
        error: state.shops.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllShop: () => dispatch(getAllShop()),
        getLogin: (shop) => dispatch(startGetLogin(shop))
    };
};

const Home = connect(mapStateToProps, mapDispatchToProps)(HomeTest);
export default Home;