import {GET_ALL_SHOPS_SUCCESS, GET_SHOPS_FAILURE, GET_ALL_SHOPS, GET_LOGIN, SUCCESS_GET_LOGIN} from "../../../store/actionType";

export const startGetAllShop= () => {
    return {
        type: GET_ALL_SHOPS
    };
};

export const saveAllShops= (data) => {
    return {
        type: GET_ALL_SHOPS_SUCCESS,
        data: data
    };
};

export const failed= (err) => {
    return {
        type: GET_SHOPS_FAILURE,
        error:err
    };
};

export const startLogin= () => {
    return {
        type: GET_LOGIN,
        status:'START'
    };
};

export const updategetLoginSuccess = (id) => {
    return {
        type: SUCCESS_GET_LOGIN,
        status:'SUCCESS',
        id: id
    };
};

export const updateMainPageSuccess = () => {
    return {
        type: SUCCESS_GET_LOGIN,
        status:'MAIN_PAGE'
    };
};

export const clearStore = () => {
    return {
        type: "CLEAR",
    };
};