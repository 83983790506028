import {updateObject} from "../../../util/updateObject";
import {GET_ALL_SHOPS_SUCCESS, GET_SHOPS_FAILURE, GET_ALL_SHOPS, GET_LOGIN, SUCCESS_GET_LOGIN} from "../../../store/actionType";


const initialState = {
    comesToHome:false,
    status: "NONE",
    allShops:[],
    shops:[],
    id:'',
    error: null
};

const shopRequestFailure = (state, action) => {
    return updateObject(state, {
        status: "ERROR",
        error: action.error
    });
};

const getShop = (state) => {
     return updateObject(state, {
         status: "START"
    });
};


const getShopSuccess = (state, action) => {
    let shops = action.data;
    // if(null === order.status) {
    //     order.status = ORDER_START;
    // }
    return updateObject(state, {
        shops: shops
    });
};

const setStatus = (state, action) => {
    return updateObject(state, {
        status: action.status
    });
};

const setSuccessLogin = (state, action) => {
    return updateObject(state, {
        comesToHome:true,
        status: action.status,
        id: action.id

    })
}

const sessionClean = (state, action) => {
    return updateObject(state, initialState);
};

const shopReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SHOPS_FAILURE:
            return shopRequestFailure(state, action);
        case GET_ALL_SHOPS:
            return getShop(state);
        case GET_ALL_SHOPS_SUCCESS:
            return getShopSuccess(state, action);
        case GET_LOGIN:
            return setStatus(state, action);
        case SUCCESS_GET_LOGIN:
            return setSuccessLogin(state, action);
        case "CLEAR":
            return sessionClean(state, action);
        default:
            return state;
    }
};

export default shopReducer;