import React, {Component} from 'react';
import './Input.css';

class DynamicDropdown extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChange(this.props.field, Number(event.target.value));
    }

    render() {
        const inpValue = null === this.props.value ? 0 : this.props.value;
        const keyField = this.props.keyField;
        const valueField = this.props.valueField;
        const textField = this.props.textField;

        let data = this.props.data.map((item) => {
            return {
                "key": item[keyField],
                "value": item[valueField],
                "text": item[textField]
            }
        });

        data.unshift({
            "key": 0,
            "value": 0,
            "text": "Choose..."
        });
        const classN = 0 === inpValue ? "selectplaceholder" : "";
        console.log("inpValue"+inpValue);
        return (
            <div data-test="dynamic-dropdown-div" className={this.props.inptype}>
                <select value={inpValue} onChange={this.handleChange} disabled={this.props.disabled} className={classN}>
                    {data.map((item) => {
                        return (
                            <option key={item.key} value={item.value}>{item.text}</option>
                        )
                    })}
                </select>
            </div>
        );
    }
}

export default DynamicDropdown;