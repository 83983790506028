import {
    CHANGE_COUNTRY,
    CHANGE_LOCALE,
    DISABLE_UI,
    ENABLE_UI,
    IS_CHANGED_LOCALE,
    UPDATE_COUNTRY_FAILURE,
    UPDATE_COUNTRY_REQUEST,
    UPDATE_COUNTRY_SUCCESS
} from "../../../store/actionType";
import {updateObject} from "../../../util/updateObject";
import {NOMAIL} from "../../order/util/mailStatus";

const initialState = {
    disabled: false,
    mailStatus: NOMAIL,
    isFirst: true,
    alias: null,
    countryRequest: false,
    currentLocale: 'nl-NL', // TODO: determine locale here
    localeChange: false,
    // locales: {
    //     'pt-BR': require('../../../../src/locales/pt-BR.json'),
    //     'de-DE': require('../../../../src/locales/de-DE.json'),
    //     'en-UK': require('../../../../src/locales/en-UK.json'),
    //     'nl-NL': require('../../../../src/locales/nl_NL.json'),
    //     'fr-FR': require('../../../../src/locales/fr-FR.json'),
    // },
    defaultCountry : "NL",
    newCountry : "",
    countries : ["NL", "FR", "EN", "DE" , "ES" , "GB" , "BR"],
    customLabels : {"NL": "NL" , "EN": "EN-UK", "GB": "EN-GB", "DE": "DE" , "FR":"FR", "PT":"BR" , "ES":"ES"}
};

const setDisabled = (state) => {
    return updateObject(state, {
        disabled: true
    });
};

const setEnabled = (state, action) => {
    let data;
    if (typeof action.data === 'undefined' || action.data === null) {
        data = {};
    } else {
        data = action.data;
    }
    data.disabled = false;
    return updateObject(state, data);
};

const setUpdateCountryRequest = (state, action) => {
    return updateObject(state, {
        alias: action.data,
        countryRequest: true
    });
};

const setUpdatedCountry = (state, action) => {
    let data = action.data;
    data.countryRequest = false;
    return updateObject(state, data);
};

const setUpdateCountryFailure = (state) => {
    return updateObject(state, {
        countryRequest: false
    });
};

const changeLocale = (state, action)=> {
    return updateObject(state, {
        currentLocale : action.locale
    });
};

const ChangedCountry = (state, action)=> {
    return updateObject(state, {
        newCountry : action.newCountry
    });
};

const isChangedLocale = (state, action)=> {
    return updateObject(state, {
        localeChange : action.localeChange
    });
};

const appearanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISABLE_UI:
            return setDisabled(state);
        case ENABLE_UI:
            return setEnabled(state, action);
        case UPDATE_COUNTRY_REQUEST:
            return setUpdateCountryRequest(state, action);
        case UPDATE_COUNTRY_SUCCESS:
            return setUpdatedCountry(state, action);
        case UPDATE_COUNTRY_FAILURE:
            return setUpdateCountryFailure(state);
        case CHANGE_LOCALE:
            return changeLocale(state, action);
        case IS_CHANGED_LOCALE:
            return isChangedLocale(state, action);
        case CHANGE_COUNTRY:
            return ChangedCountry(state, action);
        default:
            return state;
    }
};

export default appearanceReducer;