import React, {Component} from "react";
import PropTypes from "prop-types";
import "./Shop.css"
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBMask,
    MDBView
} from "mdbreact";




class Shop extends Component {

    hanlderClickCustumer = (event) => {
        event.preventDefault();
        // alert ('choose this '+ this.props.shop);
        this.props.handlerLogin(this.props.shop);
    }



    render() {
        let img = null;
        let name = null;
        if (this.props.shop.logoURL){
            // img = (<MDBCardImage  src={this.props.shop.logoURL}  className='imgboxImg' alt={this.props.shop.name}/>);
            img = (
                <div className="box">
                    <img src={this.props.shop.logoURL} className='imgboxImg'/>
                </div>
            );

        }else{
            name = (
                <div className="box">
                    <div className="boxFont">{this.props.shop.name}</div>
                </div>
            )
        }



        return(

            <MDBCard border >
                <div data-test="shop-div" onClick={this.hanlderClickCustumer}>
                <MDBView hover>

                        {/*<MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves />*/}
                        {/*<MDBCardImage  src={this.props.shop.logoURL}  className='img-fluid mw-100 mh-100 w-100 h-100' alt={this.props.shop.name}/>*/}
                        {img}
                        {name}

                    <MDBMask className="flex-center" overlay="blue-slight">
                        <p className="#f57c00 orange darken-2">{this.props.shop.name}</p>
                    </MDBMask>
                </MDBView>
                </div>

            </MDBCard>

        )
    }
}

Shop.propTypes = {
    shop: PropTypes.object.isRequired,
    handlerLogin: PropTypes.func.isRequired
}

export default Shop;


{/*<MDBCardBody>*/}
{/*    <MDBCardTitle tag="h5">{this.props.shop.name}</MDBCardTitle>*/}
{/*</MDBCardBody>*/}