import React, {Component} from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {FAILURE, INPROGRESS, RETRIEVED, WAITING} from "../util/progressType";
import {retrieveSession} from "../store/service";
import "./RetrieveSession.css";
import {MDBCardImage, MDBCol, MDBContainer, MDBRow} from "mdbreact";

class Session extends Component {

    componentDidMount() {
        if (!this.props.comesToHome) {
            this.props.createSessionRequest();
        }
    }

    render() {
        // TODO new UI design
        // TODO add customer logo
        let img = null;
        // if (this.props.logoURL) {
        //     img = (
        //         <MDBCol>
        //             <MDBCardImage
        //                 src={this.props.logoURL}
        //                 className='img-fluid mw-100 mh-100 w-100 h-100'
        //                 alt={this.props.name}/>
        //         </MDBCol>);
        // }

        return (

            <div data-test="retrieve-session">
                {{
                    [WAITING]: <div>STARTING SESSION RETRIEVAL</div>,
                    [INPROGRESS]: <div>RETRIEVING SESSION</div>, //
                    [RETRIEVED]: <div className="logo" data-test="logo-div">
                        <MDBContainer>
                            <MDBRow>
                                {img}
                                <MDBCol>
                                    {/*<div className="boxFont">{this.props.customer}</div>*/}
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer></div>,
                    [FAILURE]: <Redirect push to="/home"/>
                }[this.props.progress]}
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        comesToHome: state.shops.comesToHome,
        progress: state.session.progress,
        customer: state.session.shop.name,
        logoURL: state.session.shop.logoURL
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createSessionRequest: () => dispatch(retrieveSession())
    };
};

const RetrieveSession = connect(mapStateToProps, mapDispatchToProps)(Session);

export default RetrieveSession;