import {LOGIN_CLEAN, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_VALIDATED} from "../../../store/actionType";

/**
 * Start Login
 * @returns {{type: *}}
 */
export const startLogin = () => {
    return {
        type: LOGIN_REQUEST
    }
};

/**
 * Validate Login
 * @returns {{type: *}}
 */
export const validateLogin = () => {
    return {
        type: LOGIN_VALIDATED
    }
};

/**
 * Invalidate Login
 * @returns {{type: *}}
 */
export const invalidateLogin = () => {
    return {
        type: LOGIN_FAILURE
    }
};

/**
 * CLEAN LOGIN
 * @returns {{type: *}}
 */
export const cleanLogin = () => {
    return {
        type: LOGIN_CLEAN
    }
};