import React, {Component} from "react";
import PropTypes from "prop-types";
import {MDBCardGroup, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import Shop from "./Shop";


class Shops extends Component {

    // handlerLogin = (shop) => {
    //     // alert(shop);
    //     this.props.getLogin(shop);
    // }

    render() {
        let i = 0;
        let all = null;

        return(
            <div data-test="shops-div">
                <MDBContainer>
                    {/*<MDBRow >*/}
                    <MDBCardGroup column>
                        {this.props.allShops.map( (shop)  => (

                            //preciso colocar 4 em cada
                                    <Shop
                                        key={shop.id}
                                        shop={shop}
                                        handlerLogin={this.props.handlerLogin}
                                    />
                        ))}
                    </MDBCardGroup>
                    {/*</MDBRow>*/}
                </MDBContainer>
            </div>
        )
    };
}

Shops.propType = {
    allShops: PropTypes.array.isRequired,
    handlerLogin: PropTypes.func.isRequired
}

export default Shops;