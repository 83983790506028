import React, {Component} from 'react';
import intl from "react-intl-universal";
import {
    ORDER_DONE,
    ORDER_ERROR,
    ORDER_NEW,
    ORDER_SHIPPING,
    ORDER_START
} from "../util/progressState";
import LinkWebshop from "../container/LinkWebshop";
import {ORDER_COUNTRY, ORDER_EXPIRED, ORDER_FAILURE, ORDER_NOTFOUND} from "../util/failureState";
import PropTypes from "prop-types";
import {SENT} from "../util/mailStatus";

class UserMessages extends Component {

    render() {
        const messageState = this.props.orderState;
        const isFirst = this.props.isFirst;
        return (
            <div data-test="user-messages">
                {((ORDER_START === messageState) || (ORDER_ERROR === messageState)) &&
                <div>
                    <span>{intl.get('user_messages.messagem1')}</span>
                </div>
                }
                {(((ORDER_SHIPPING === messageState) || (ORDER_DONE === messageState)) && isFirst) &&
                <div>
                    <span>{intl.get('user_messages.messagem2')}</span><br/>
                    {(this.props.mailStatus === SENT) &&
                    <span>{intl.get('user_messages.messagem3', {email:this.props.email})}</span>}
                </div>
                }
                {(((ORDER_SHIPPING === messageState) || (ORDER_DONE === messageState)) && !isFirst) &&
                <div>
                    <span>{intl.get('user_messages.messagem4')}</span><br/>
                    <span>{intl.get('user_messages.messagem5')} <LinkWebshop/>.</span>
                </div>
                }
                {(ORDER_EXPIRED === messageState) &&
                <div>
                    <span>{intl.get('user_messages.messagem6')}</span><br/>
                    <span>{intl.get('user_messages.messagem7')} <LinkWebshop/>.</span>
                </div>
                }
                {(ORDER_FAILURE === messageState) &&
                <div>
                    <span>{intl.get('user_messages.messagem8')}</span><br/>
                    <span>{intl.get('user_messages.messagem9')} <LinkWebshop/>.</span>
                </div>
                }
                {(ORDER_COUNTRY === messageState) &&
                <div>
                    <span> {intl.get('user_messages.messagem10', {country:this.props.country} )}</span><br/>
                    <span> {intl.get('user_messages.messagem11')} <LinkWebshop/>.</span>
                </div>
                }
                {(ORDER_NOTFOUND === messageState) &&
                <div>
                    <span>{intl.get('user_messages.messagem12')}</span><br/>
                    <span>{intl.get('user_messages.messagem13')} <LinkWebshop/>.</span>
                </div>
                }
                { (ORDER_NEW === messageState) &&
                <div>
                    <span>{intl.get('footer.instructions-for-use3')}</span>
                </div>
                }
            </div>
        );
    }
};

UserMessages.propTypes = {
    orderState: PropTypes.string,
    isFirst: PropTypes.bool.isRequired,
    country: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mailStatus: PropTypes.string.isRequired
};

export default UserMessages;
