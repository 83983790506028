import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ViewConsumer from "./module/order/container/ViewConsumer";
import AutomaticLogin from "./module/login/container/AutomaticLogin";
import Home from "./module/home/component/Home";


const Routes = () => (
    <main>
      <Switch>
          <Route exact path='/' component={Home}/>
          <Route path="/home" component={Home}/>
          <Route path="/login" component={AutomaticLogin}/>
          <Route path="/main" component={ViewConsumer}/>
      </Switch>
    </main>
)
export default Routes;
