import axios from "axios";
import {failedSession, startSessionRequest, retrievedSession} from "./action";

/**
 * Returns Redux Thunk function that dispatches
 * `startSessionRequest` action
 *  and `postRequest` action
 * @returns {Function} - Redux Thunk function.
 */
export const retrieveSession = () => {
    return dispatch => {
        dispatch(startSessionRequest());
        dispatch(postRequest());
    }
};

/**
 * Returns Redux Thunk function that initiates an axios request
 *    and dispatches the response `retrievedSession` as a success
 *    and dispatches the response `failedSession` as exception
 * @returns {Function} - Redux Thunk function.
 */
const postRequest = () => {
    return dispatch => {
        axios.get('/api/session'
        ).then(response => {
            dispatch(retrievedSession(response.data));
        }).catch(err => {
            dispatch(failedSession(err));
        });
    }
};