import React from 'react';
import ReactDOM from 'react-dom';
import 'react-flags-select/css/react-flags-select.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import './index.css';
import * as serviceWorker from './registerServiceWorker';
import store from './configureStore';
import {Provider} from 'react-redux'
import App from "./App";
import { BrowserRouter } from "react-router-dom"

        ReactDOM.render(
            <Provider store={store}>
            <BrowserRouter >
                <App/>
            </BrowserRouter>
            </Provider>,
            document.getElementById('root')
        );
serviceWorker.unregister();
