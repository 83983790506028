import axios from "axios";
import {cleanLogin, invalidateLogin, startLogin, validateLogin} from "./action";
import {sessionClean} from "../../session/store/action";
import {clearStore} from "../../shop/store/shopAction";

/**
 *  Returns Redux Thunk function that dispatches
 *  `startLogin`
 *  `createLoginRequest`
 *  and validateLogin as exception
 * @param id string
 * @returns {Function|{type: *}}
 */
export const handleLogin = (id) => {
    if (typeof id === 'undefined' || id === null || id.length === 0) {
        return invalidateLogin();
    } else {
        const username = id.slice(0, id.length / 2);
        const password = id.slice(id.length / 2);
        return dispatch => {
            dispatch(startLogin());
            dispatch(createLoginRequest(username, password));
        }
    }
};

/**
 * Returns Redux Thunk function that initiates an axios request
 *    and dispatches the response `validateLogin` as a success
 *    and dispatches the response `invalidateLogin` as exception
 * @param username string
 * @param password string
 * @returns {Function}
 */
const createLoginRequest = (username, password) => {
    return dispatch => {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        axios.post('/api/login', params
        ).then(response => {
            dispatch(validateLogin());
        }).catch(err => {
            dispatch(invalidateLogin());
        });
    }
};

/**
 * Returns Redux Thunk function that initiates an axios request to logout the system
 *
 * @returns {Function}
 */
export const createLogoutRequest = () => {
    return dispatch => {
        axios.post('/api/logout'
        ).then(response => {
            dispatch(sessionClean());
            dispatch(cleanLogin());
            dispatch(clearStore());
        }).catch(err => {
            dispatch(sessionClean());
            dispatch(cleanLogin());
            dispatch(clearStore());
        });
    }
};