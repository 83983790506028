import axios from "axios";
import {saveAllShops, startGetAllShop, failed, updategetLoginSuccess, clearStore} from "./shopAction";
import {updateCountryFailure, updateCountrySuccess} from "../../appearance/store/action";

import {retrievedSession, sessionClean} from "../../session/store/action";
import {createLogoutRequest, handleLogin} from "../../login/store/service";


/**
 * Returns Redux Thunk function that dispatches
 * `updateCountryRequest` action
 *  and `countryRequest` action
 * @returns {Function} - Redux Thunk function.
 */
export const getAllShop = () => {
    return dispatch => {
        // dispatch(sessionClean());
        dispatch(createLogoutRequest());
        dispatch(startGetAllShop());
        dispatch(postRequest());
    }
};

/**
 * Returns Redux Thunk function that initiates an axios request
 *    and dispatches the response `saveAllShops` as a success
 *    and dispatches the response `failed` as exception
 *
 * @returns {Function} - Redux Thunk function.
 */
const postRequest = () => {
    return dispatch => {
        axios.get('/api/shop/all'
        ).then(response => {
            dispatch(saveAllShops(response.data.shops));
        }).catch(err => {
            dispatch(failed(err));
            // dispatch(saveAllShops(res.shops));
        });
    }
};

/**
 * Returns Redux Thunk function that dispatches
 * `startLogin` action
 *  and `getLogin` action
 * @returns {Function} - Redux Thunk function.
 */
export const startGetLogin = (shop) => {
    return dispatch => {
        // dispatch(startLogin());
        dispatch(getLogin(shop));
    }
};


/** post
 * Returns Redux Thunk function that initiates an axios request
 *    and dispatches the response `` as a success
 *    and dispatches the response `` as exception
 * @param {object} shop
  * @returns {Function} - Redux Thunk function.
 */
const getLogin = (shop) => {
    return dispatch => {
        axios.post('/api/session', shop
        ).then(response => {
            const session = ({
                shop : shop,
                createdOrder: false
            })
            dispatch(handleLogin(response.data.id));
            dispatch(updategetLoginSuccess(response.data.id));
            dispatch(retrievedSession(session));
            // dispatch(updateCountrySuccess(response.data));
        }).catch(() => {
            dispatch(updateCountryFailure());
        });
    }
};

