import React, {Component} from 'react';
import DynamicDropdown from "../../../ui/input/DynamicDropdown";
import PropTypes from "prop-types";

class AnswerBox extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(id, value) {
        this.props.onChange(id, value);
    }

    render() {
        if (this.props.finished) {
            return <input type="text" disabled={true} value={this.props.text}/>
        }

        return (
            <DynamicDropdown
                field={this.props.field}
                inptype={this.props.inptype}
                keyField="id"
                valueField="id"
                textField="text"
                value={this.props.reason}
                onChange={this.handleChange}
                data={this.props.answers}
                disabled={this.props.disabled}
            />
        );
    }
}

AnswerBox.propTypes = {
    field: PropTypes.number.isRequired,
    inptype: PropTypes.string.isRequired,
    reason: PropTypes.number,
    text: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    answers: PropTypes.array.isRequired,
    finished: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default AnswerBox;