import intl from "react-intl-universal";

export const loadLocales = (locale) => {
    // init method will load CLDR locale data according to currentLocale
    // react-intl-universal is singleton, so you should init it only once in your app
    return(intl.init({
        currentLocale: locale,//'en-US', // TODO: determine locale here
        locales: {
            'pt-BR':
                require('../../src/locales/pt-BR.json'),
            'de-DE':
                require('../../src/locales/de-DE.json'),
            'en-UK':
                require('../../src/locales/en-UK.json'),
            'nl-NL':
                require('../../src/locales/nl_NL.json'),
            'fr-FR':
                require('../../src/locales/fr-FR.json'),
            'es-ES':
                require('../../src/locales/es-ES.json')
        }
    })
    )
}