import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IntInput from "../../../ui/input/IntInput";
import "./OrderItems.css";
import AnswerBox from "../../answer/container/AnswerBox";

class OrderItem extends Component {

    /**
     * @method constructor
     * @param {object} props - Component props.
     * @returns {undefined}
     */
    constructor(props) {
        super(props);
        this.handleReturnedChange = this.handleReturnedChange.bind(this);
        this.handleReasonChange = this.handleReasonChange.bind(this);
        this.input = React.createRef();
    }

    /**
     *
     */
    componentDidMount() {
        // this.input.current.focus();
    }

    /**
     * Run `` action on the submitted Changes (if it's not empty)
     * @method handleChange
     * @param id string
     * @param value number
     */
    handleReturnedChange(id, value) {
        this.props.onChange(id, {field: "returned", value: value});
    }

    handleReasonChange(id, value) {
        this.props.onChange(id, {field: "reason", value: value});
    }

    render() {
        return (
            <tr data-test="orderItem-tr">
                <td className="td-left" data-test="orderItem-td-name">
                    {this.props.name}
                </td>
                <td className="td-left" data-test="orderItem-td-sku">
                    {this.props.sku}
                </td>
                <td className="td-center" data-test="orderItem-td-delivered">
                    {this.props.delivered}
                </td>
                <td className="td-center" data-test="orderItem-td-IntInput">
                    <IntInput
                        id="orderItem-IntInput"
                        ref={this.input}
                        field={this.props.id}
                        inptype="uinp"
                        holder="0"
                        max={this.props.delivered}
                        value={this.props.returned}
                        onChange={this.handleReturnedChange}
                        disabled={this.props.disabled}
                    />
                </td>
                {(this.props.showAnswerColumn) &&
                <td className="td-center" data-test="orderItem-td-DynamicDropdown">
                    {((this.props.returned !== null) && (this.props.returned > 0)) &&
                    <AnswerBox
                        field={this.props.id}
                        inptype="uinp"
                        reason={this.props.reason}
                        text={this.props.reasonText}
                        onChange={this.handleReasonChange}
                        answers={this.props.answers}
                        finished={this.props.finished}
                        disabled={this.props.disabled}
                    />
                    }
                </td>
                }
            </tr>
        );
    }
}

OrderItem.propTypes = {
    id: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    delivered: PropTypes.number.isRequired,
    returned: PropTypes.number,
    reason: PropTypes.number,
    reasonText: PropTypes.string,
    answers: PropTypes.array.isRequired,
    finished: PropTypes.bool.isRequired,
    showAnswerColumn: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default OrderItem;
