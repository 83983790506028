import React, {Component} from 'react';
import OrderItem from "./OrderItem";
import "./OrderItems.css";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
class OrderItems extends Component {

    /**
     * @method constructor
     * @param {object} props - Component props.
     * @returns {undefined}
     */
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Run `` action on the submitted Changes (if it's not empty)
     * @method handleChange
     * @param id string
     * @param value number
     */
    handleChange(id, data) {
        this.props.onChange(id, data);
    }

    determineShowAnswerColumn() {
        let result;
        if (this.props.finished) {
            result = false;
            for (const item of this.props.items) {
                result = result || (null !== item.reason);
            }
        } else {
            result = (this.props.answers.length > 0);
        }
        return result;
    }

    render() {
        const showAnswerColumn = this.determineShowAnswerColumn();
        const colSpan = showAnswerColumn ? "4" : "3";
        const tableItems = this.props.items.map((item) =>
            <OrderItem key={item.id}
                       id={item.id}
                       sku={item.sku}
                       name={item.name}
                       delivered={item.delivered}
                       returned={item.returned}
                       reason={item.reason}
                       reasonText={item.reasonText}
                       answers={this.props.answers}
                       finished={this.props.finished}
                       showAnswerColumn={showAnswerColumn}
                       disabled={this.props.disabled}
                       onChange={this.handleChange}/>);
        return (
            <table className="table-quantity" data-test="table-quantity">
                <thead data-test="table-quantity-head">
                <tr>
                    <th
                        id="column-product"
                        data-test="column-product"
                    >{intl.get('return_table.product')}
                    </th>
                    <th
                        id="column-sku"
                        data-test="column-sku"
                    >{intl.get('return_table.number')}
                    </th>
                    <th
                        id="column-delivered"
                        data-test="column-delivered"
                    >{intl.get('return_table.quantity')}
                    </th>
                    <th
                        id="column-returned"
                        data-test="column-returned"
                    >{intl.get('return_table.quantity_return')}
                    </th>
                    {showAnswerColumn &&
                    <th
                        id="column-reason"
                        data-test="column-reason"
                    >{intl.get('return_table.reason')}
                    </th>
                    }
                </tr>
                </thead>
                <tbody data-test="table-quantity-tbody">
                {tableItems}
                <tr>
                    <td colSpan={colSpan} className="td-right" data-test="td-right">
                        <b>{intl.get('return_table.total')}</b>
                    </td>
                    <td className="td-center" data-test="td-center">
                        <b>{this.props.total}</b>
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }
}

OrderItems.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            sku: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            delivered: PropTypes.number.isRequired,
            returned: PropTypes.number,
            reason: PropTypes.number,
            reasonText: PropTypes.string
        })
    ).isRequired,
    total: PropTypes.number, //TODO isRequired?
    onChange: PropTypes.func.isRequired,
    answers: PropTypes.array.isRequired,
    finished: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
}
export default OrderItems;