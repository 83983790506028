import {updateObject} from "../../../util/updateObject";
import {
    DOWNLOAD_SUCCESS,
    SEARCH_ORDER_SUCCESS,
    ORDER_REQUEST_FAILURE,
} from "../../../store/actionType";
import {
    ORDER_DONE,
    ORDER_ERROR,
    ORDER_START
} from "../util/progressState";
import {ORDER_FAILURE} from "../util/failureState";

const initialState = {
    status: ORDER_START,
    error: null
};

const orderRequestFailure = (state) => {
    return updateObject(state, {
        status: ORDER_ERROR,
        error: ORDER_FAILURE
    });
};

const searchOrderSuccess = (state, action) => {
    let order = action.data;
    if(null === order.status) {
        order.status = ORDER_START;
    }
    return updateObject(state, order);
};

const downloadSuccess = (state) => {
    return updateObject(state, {
        status: ORDER_DONE
    });
};

const orderReturnReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_REQUEST_FAILURE:
            return orderRequestFailure(state);
        case SEARCH_ORDER_SUCCESS:
            return searchOrderSuccess(state, action);
        case DOWNLOAD_SUCCESS:
            return downloadSuccess(state);
        default:
            return state;
    }
};

export default orderReturnReducer;