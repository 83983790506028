import {
    DOWNLOAD_SUCCESS,
    SEARCH_ORDER_SUCCESS,
    ORDER_REQUEST_FAILURE,
} from "../../../store/actionType";

export const retrievedOrder = (data) => {
    return {
        type: SEARCH_ORDER_SUCCESS,
        data: data
    };
};

export const orderRequestFailure = (error) => {
    return {
        type: ORDER_REQUEST_FAILURE,
        error: error
    };
};

export const downloadedLabel = () => {
    return {
        type: DOWNLOAD_SUCCESS
    };
};
