import React, {Component} from 'react';
import './Input.css';

class IntInput extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChange(this.props.field, Number(event.target.value));
    }

    render() {
        // const inpValue = null === this.props.value ? '' : this.props.value;
        // const inpHolder = this.props.holder;
        const inpValue7 = this.props.value;
        const inpValue = 0 === this.props.value ? "" : this.props.value;
        const inpHolder = this.props.holder;
        return(
             <div data-test="int-input-div"
                 className={this.props.inptype}>
                <input type="number"
                       step="1"
                       min="0"
                       max={this.props.max}
                       placeholder={inpHolder}
                       value={inpValue}
                       onChange={this.handleChange}
                       disabled={this.props.disabled}
                       id={this.props.id}
                />



            </div>
        );
    }
}

export default IntInput;