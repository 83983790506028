import {LOGIN_CLEAN, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_VALIDATED} from "../../../store/actionType";
import {AUTHENTICATED, FAILURE, INPROGRESS, WAITING} from "../util/progressType";
import {updateObject} from "../../../util/updateObject";

const initialState = {
    progress: WAITING
};

const loginRequest = (state) => {
    return updateObject(state, {
        progress: INPROGRESS
    });
};

const loginSuccess = (state) => {
    return updateObject(state, {
        progress: AUTHENTICATED
    });
};

const loginFailure = (state) => {
    return updateObject(state, {
        progress: FAILURE
    });
};

const loginClean = (state) => {
    return updateObject(state, initialState);
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return loginRequest(state);
        case LOGIN_VALIDATED:
            return loginSuccess(state);
        case LOGIN_FAILURE:
            return loginFailure(state);
        case LOGIN_CLEAN:
            return loginClean(state);
        default:
            return state;
    }
};

export default loginReducer;