import axios from "axios";
import {answerFailure, answerRequest, answerSuccess} from "./action";
import {ORDER_NEW} from "../../order/util/progressState";

export const retrieveAnswers = (orderProgressState, locale) => {
    return dispatch => {
        if (ORDER_NEW === orderProgressState) {
            dispatch(answerRequest());
            dispatch(performRequest(locale));
        }
    }
};

const performRequest = (locale) => {
    return dispatch => {
        axios.get("api/answer/all", {
            params: {"locale": locale}
        }).then(response => {
            dispatch(answerSuccess(response.data));
        }).catch(() => {
            dispatch(answerFailure());
        });
    }
};
