export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_VALIDATED = 'LOGIN_VALIDATED';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CLEAN = 'LOGIN_CLEAN';

export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_FAILURE = 'SESSION_FAILURE';
export const SESSION_CLEAN   = 'SESSION_CLEAN';

export const ORDER_REQUEST_FAILURE = 'ORDER_REQUEST_FAILURE';

export const SEARCH_ORDER_SUCCESS = 'SEARCH_ORDER_SUCCESS';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';

export const DISABLE_UI = 'DISABLE_UI';
export const ENABLE_UI = 'ENABLE_UI';
export const UPDATE_COUNTRY_REQUEST = 'UPDATE_COUNTRY_REQUEST';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_FAILURE = 'UPDATE_COUNTRY_FAILURE';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';
export const IS_CHANGED_LOCALE = 'IS_CHANGED_LOCALE';

export const GET_ALL_SHOPS = 'GET_ALL_SHOPS';
export const GET_ALL_SHOPS_SUCCESS = 'GET_ALL_SHOPS_SUCCESS';
export const GET_SHOPS_FAILURE = 'GET_SHOPS_FAILURE';
export const GET_LOGIN = 'GET_LOGIN';
export const SUCCESS_GET_LOGIN = "SUCCESS_GET_LOGIN";

export const ANSWER_REQUEST = 'ANSWER_REQUEST';
export const ANSWER_SUCCESS = 'ANSWER_SUCCESS';
export const ANSWER_FAILURE = 'ANSWER_FAILURE';
