import React, {Component} from 'react';
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {ORDER_ERROR, ORDER_START} from "../util/progressState";
import TextInput from "../../../ui/input/TextInput";
import EmailInput from "../../../ui/input/EmailInput";
import GenButton from "../../../ui/button/GenButton";
import Message from "../../../ui/output/Message";
import ErrorMessage from "../../../ui/output/ErrorMessage";
import UserMessages from "../component/UserMessages";
import {retrieveOrder, search} from "../store/service";
import "./SearchOrder.css";



class Order extends Component {

    constructor(props) {
        super(props);
        this.order = React.createRef();

        this.state = {
            "orderNumber": '',
            "emailAddress": ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

    }

    // focusTextInput() {
    //     // Explicitly focus the text input using the raw DOM API
    //     // Note: we're accessing "current" to get the DOM node
    //     this.order.current.focus();
    // }

    componentDidMount() {
        if(this.props.createdOrder) {
            this.props.retrieveOrder({"locale": this.props.currentLocale});
        }
        this.order.current.focus();
    }
    // componentWillMount() {
    //     console.log(this.order);
    //     // this.order.current.focus();
    //     // this.focusTextInput();
    // }

    handleChange(field, value) {
        this.setState({[field]: value});

    }

    handleSearch() {
        this.props.search({
            "orderNumber": this.state.orderNumber,
            "email": this.state.emailAddress
        }, {
            "locale": this.props.currentLocale
        });
    }

    hasValue(field) {
        return ((typeof field !== 'undefined') && (null !== field) && ('' !== field));
    }

    validateFilters() {
        return this.hasValue(this.state.orderNumber) && this.hasValue(this.state.emailAddress);
    }

    render() {
        const searchActive = (ORDER_START === this.props.orderProgressState) || (ORDER_ERROR === this.props.orderProgressState);
        const inputDisabled = this.props.disabled || !searchActive;
        const searchDisabled = this.props.disabled || !this.validateFilters();
        const inputClass = (ORDER_ERROR === this.props.orderProgressState) ? "falp" : "uinp";
        const orderNumber = this.props.createdOrder ? this.props.orderNumber : this.state.orderNumber;
        const emailAddress = this.props.createdOrder ? this.props.emailAddress : this.state.emailAddress;

        return (
            <div data-test="search-order" className="search-order">
                <Message>
                    <UserMessages orderState={this.props.orderProgressState}
                                  isFirst={this.props.isFirst}
                                  country={this.props.country}
                                  email={emailAddress}
                                  mailStatus={this.props.mailStatus}
                                  isChangingCheLanguage={this.props.isChangingCheLanguage}/>
                </Message>
                <br/>
                <div data-test="search" id="search">
                    <div data-test="searchfilters" id="searchfilters">
                        <TextInput field="orderNumber"
                                   inptype={inputClass}
                                   holder={intl.get('search_order.orderNumber')}
                                   size="30"
                                   value={orderNumber}
                                   onChange={this.handleChange}
                                   disabled={inputDisabled}
                                   referen={this.order}
                                   isChangingCheLanguage={this.props.isChangingCheLanguage}/>
                        <EmailInput field="emailAddress"
                                   inptype={inputClass}
                                   holder={intl.get('search_order.emailAddress')}
                                   size="40"
                                   value={emailAddress}
                                   onChange={this.handleChange}
                                   disabled={inputDisabled}
                                   isChangingCheLanguage={this.props.isChangingCheLanguage}/>
                    </div>
                    {searchActive &&
                    <div data-test="searchfilters" id="searchaction">
                        <GenButton text={intl.get('search_order.button')}
                                   onClick={this.handleSearch}
                                   disabled={searchDisabled}
                                   isChangingCheLanguage={this.props.isChangingCheLanguage}/>
                    </div>
                    }
                </div>
                <ErrorMessage>
                    <UserMessages orderState={this.props.orderFailureState}
                                  isFirst={this.props.isFirst}
                                  country={this.props.country}
                                  email={emailAddress}
                                  mailStatus={this.props.mailStatus}
                                  isChangingCheLanguage={this.props.isChangingCheLanguage}/>
                </ErrorMessage>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        orderProgressState: state.orderReturn.status,
        orderFailureState: state.orderReturn.error,
        orderNumber: state.orderReturn.orderNumber,
        emailAddress: state.orderReturn.email,
        isFirst: state.appearance.isFirst,
        disabled: state.appearance.disabled,
        country: state.appearance.alias,
        mailStatus: state.appearance.mailStatus,
        createdOrder: state.session.createdOrder,
        currentLocale: state.appearance.currentLocale,
        isChangingCheLanguage: state.appearance.localeChange
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        search: (order, params) => dispatch(search(order, params)),
        retrieveOrder: (params) => dispatch(retrieveOrder(params))
    };
};

const SearchOrder = connect(mapStateToProps, mapDispatchToProps)(Order);

export default SearchOrder;