/*
This folder configure the redux store, middlewares and reducers
 */

// import rootReducer from './store/rootReducer';
import { createStore, applyMiddleware, compose  } from 'redux'
import thunk from 'redux-thunk';
import loginReducer from "./module/login/store/reducer";
import orderReturnReducer from "./module/order/store/reducer";
import sessionReducer from "./module/session/store/reducer";
import appearanceReducer from "./module/appearance/store/reducer";
import shopReducer from "./module/shop/store/shopReducer";
import answerReducer from "./module/answer/store/reducer";
import {combineReducers} from "redux";

const combine = combineReducers({
    login: loginReducer,
    orderReturn: orderReturnReducer,
    session: sessionReducer,
    appearance: appearanceReducer,
    shops: shopReducer,
    qa: answerReducer
});

/**
 * Middleware log all the actions
 * @param store
 * @returns {function(*): function(*=): *}
 */
const logger = store => {
    return next => {
        return action => {
            console.log('[Middleware] Dispatching', action);
            const result = next(action);
            console.log('[Middleware] next state?!?', store.getState());
            return result;
        }
    }
};

/**
 * Configuration for use dev tools
 *  DO NOT USE IN PRODUCTION please
 * @type {(() => <R>(a: R) => R) | (<F extends Function>(f: F) => F) | (<A, R>(f1: (b: A) => R, f2: Func0<A>) => Func0<R>) | (<A, T1, R>(f1: (b: A) => R, f2: Func1<T1, A>) => Func1<T1, R>) | (<A, T1, T2, R>(f1: (b: A) => R, f2: Func2<T1, T2, A>) => Func2<T1, T2, R>) | (<A, T1, T2, T3, R>(f1: (b: A) => R, f2: Func3<T1, T2, T3, A>) => Func3<T1, T2, T3, R>) | (<A, B, R>(f1: (b: B) => R, f2: (a: A) => B, f3: Func0<A>) => Func0<R>) | (<A, B, T1, R>(f1: (b: B) => R, f2: (a: A) => B, f3: Func1<T1, A>) => Func1<T1, R>) | (<A, B, T1, T2, R>(f1: (b: B) => R, f2: (a: A) => B, f3: Func2<T1, T2, A>) => Func2<T1, T2, R>) | (<A, B, T1, T2, T3, R>(f1: (b: B) => R, f2: (a: A) => B, f3: Func3<T1, T2, T3, A>) => Func3<T1, T2, T3, R>) | (<A, B, C, R>(f1: (b: C) => R, f2: (a: B) => C, f3: (a: A) => B, f4: Func0<A>) => Func0<R>) | (<A, B, C, T1, R>(f1: (b: C) => R, f2: (a: B) => C, f3: (a: A) => B, f4: Func1<T1, A>) => Func1<T1, R>) | (<A, B, C, T1, T2, R>(f1: (b: C) => R, f2: (a: B) => C, f3: (a: A) => B, f4: Func2<T1, T2, A>) => Func2<T1, T2, R>) | (<A, B, C, T1, T2, T3, R>(f1: (b: C) => R, f2: (a: B) => C, f3: (a: A) => B, f4: Func3<T1, T2, T3, A>) => Func3<T1, T2, T3, R>) | (<R>(f1: (b: any) => R, ...funcs: Function[]) => (...args: any[]) => R) | (<R>(...funcs: Function[]) => (...args: any[]) => R)}
 */
const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

export const enhancer = composeEnhancers(
    applyMiddleware(logger, thunk),
    // other store enhancers if any
);

export default createStore(combine, enhancer);