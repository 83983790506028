import {SESSION_CLEAN, SESSION_FAILURE, SESSION_REQUEST, SESSION_SUCCESS} from "../../../store/actionType";
import {FAILURE, INPROGRESS, RETRIEVED, WAITING} from "../util/progressType";
import {updateObject} from "../../../util/updateObject";

const initialState = {
    progress: WAITING,
    shop:{
        customer:'',
        logoURL:''
    }
};

const sessionRequest = (state) => {
    return updateObject(state, {
        progress: INPROGRESS
    });
};

const sessionSuccess = (state, action) => {
    let session = action.data;
    session.progress = RETRIEVED;
    return updateObject(state, session);
};

const sessionFailure = (state, action) => {
    return updateObject(state, {
        progress: FAILURE,
        error: action.error
    });
};

const sessionClean = (state, action) => {
    return updateObject(state, initialState);
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SESSION_REQUEST:
            return sessionRequest(state);
        case SESSION_SUCCESS:
            return sessionSuccess(state, action);
        case SESSION_FAILURE:
            return sessionFailure(state, action);
        case SESSION_CLEAN:
            return sessionClean(state, action);
        default:
            return state;
    }
};

export default sessionReducer;