import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from "react-router";
import {connect} from "react-redux";
import queryString from "querystring";
import {AUTHENTICATED, FAILURE, INPROGRESS, WAITING} from "../util/progressType";
import {handleLogin} from "../store/service";

export class Login extends Component {

    componentDidMount() {
        const values = queryString.parse(this.props.location.search.slice(1));
        this.props.createLoginRequest(values.id);
    }

    render() {
        // TODO new UI design
        return (
            <div data-test="automatic-login-div">
                {{
                    [WAITING]: <span>STARTING USER LOGIN</span>,
                    [INPROGRESS]: <span>LOGGING IN USER</span>,
                    [AUTHENTICATED]: <Redirect to="/main"/>,
                    [FAILURE]: <Redirect to="/home"/>
                }[this.props.progress]}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.login.progress
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createLoginRequest: id => dispatch(handleLogin(id))
    };
};


Login.propTypes = {
    progress:PropTypes.string.isRequired,
    createLoginRequest:PropTypes.func.isRequired
}

const AutomaticLogin = connect(mapStateToProps, mapDispatchToProps)(Login);

export default AutomaticLogin;