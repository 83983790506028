import {MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem} from "mdbreact";
import Logo from "./thumbnail_retourenshop.png";
import ReactFlagsSelect from "react-flags-select";
import React, {Component} from "react";
import "./HeaderPage.css";
import {connect} from "react-redux";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import {changeLocaleApp} from "../../module/appearance/store/service";

class HeaderPage extends Component {

    flagCod = (flagCod) => {
        // console.log(flagCod);
        this.props.onSelect({
            flagCod: flagCod,
            isChangingCheLanguage: this.props.isChangingCheLanguage,
            consumerCountry: this.props.consumerCountry,
            orderProgressState: this.props.orderProgressState
        });
    };

    render() {
        console.log(intl.get('header.home-title'));
        let title = null
        if(this.props.shop.customer){
            if (this.props.shop.logoURL){
                console.log("flagCod  "+this.props.shop.logoURL);
                title = ( <img style={{ height: "6.5rem"}} src={this.props.shop.logoURL}/> )
            }else{
                title = ( <div className="HeaderFont">{this.props.shop.name}</div> )
            }


        }else{
            title = (<div className="HeaderFont">{intl.getHTML('header.home-title')}</div> )
        }

        let logo = (
            <img style={{ height: "6.5rem"}} src={Logo}/>
        )

        if(this.props.shop.customer){
            logo = null
        }

        return (
            <MDBNavbar data-test="header" color="#fafafa grey lighten-5" dark expand="md" fixed="top" >
                <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
                    {/*  link with store to change img */}
                    {logo}

                </MDBNavbarBrand>
                {/*  link with store to change text */}
                <MDBNavbarNav center>
                    {title}
                </MDBNavbarNav>
                <MDBNavbarNav right>
                    <MDBNavItem>
                        <ReactFlagsSelect
                            defaultCountry={this.props.defaultCountry}
                            countries={this.props.countries}
                            customLabels={this.props.customLabels}
                            selectedSize={18}
                            optionsSize={18}
                            placeholder=" "
                            showSelectedLabel={false}
                            showOptionLabel={false}
                            onSelect={(flagCod) => this.flagCod(flagCod)}
                        />
                    </MDBNavItem>
                </MDBNavbarNav>
            </MDBNavbar>
        )
    }
}

HeaderPage.propTypes = {
    shop:PropTypes.object,
    error:PropTypes.string,
    countries:PropTypes.array,
    localeChange:PropTypes.bool,
    customLabels:PropTypes.object,
    currentLocale:PropTypes.string,
    defaultCountry:PropTypes.string,
    orderProgressState:PropTypes.string,
    onSelect:PropTypes.func.isRequired,
    isChangingCheLanguage: PropTypes.bool,
    consumerCountry: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        shop:          state.session.shop,
        error:         state.shops.error,
        countries:     state.appearance.countries,
        localeChange:  state.appearance.localeChange,
        customLabels:  state.appearance.customLabels,
        currentLocale: state.appearance.currentLocale,
        defaultCountry:state.appearance.defaultCountry,
        orderProgressState:state.orderReturn.status,
        isChangingCheLanguage: state.appearance.localeChange,
        consumerCountry: state.appearance.isoCode
    };
};

// TODO onChange flag action
const mapDispatchToProps = (dispatch) => {
    return {
        onSelect: (params) => dispatch(changeLocaleApp(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPage);