import {ANSWER_FAILURE, ANSWER_REQUEST, ANSWER_SUCCESS} from "../../../store/actionType";

export const answerRequest = () => {
    return {
        type: ANSWER_REQUEST
    }
};

export const answerSuccess = (data) => {
    return {
        type: ANSWER_SUCCESS,
        data: data
    }
};

export const answerFailure = () => {
    return {
        type: ANSWER_FAILURE
    }
};
