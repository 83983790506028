/*
Actions to manipulate app appearance
 */
import {
    CHANGE_COUNTRY,
    CHANGE_LOCALE,
    DISABLE_UI,
    ENABLE_UI,
    IS_CHANGED_LOCALE,
    UPDATE_COUNTRY_FAILURE,
    UPDATE_COUNTRY_REQUEST,
    UPDATE_COUNTRY_SUCCESS
} from "../../../store/actionType";

/**
 * This action disables the application
 * @returns {{type: *}}
 */
export const disableUI = () => {
    return {
        type: DISABLE_UI
    };
};

/**
 * This action enable the application
 * @param data
 * @returns {{data: *, type: *}}
 */
export const enableUI = (data) => {
    return {
        type: ENABLE_UI,
        data: data
    };
};

/**
 * This action update Country
 * @param data
 * @returns {{data: *, type: *}}
 */
export const updateCountryRequest = (data) => {
    return {
        type: UPDATE_COUNTRY_REQUEST,
        data: data
    };
};

/**
 * This action update Country
 * @param data
 * @returns {{data: *, type: *}}
 */
export const updateCountrySuccess = (data) => {
    return {
        type: UPDATE_COUNTRY_SUCCESS,
        data: data
    };
};

/**
 * This action update Country fail
 * @returns {{type: *}}
 */
export const updateCountryFailure = () => {
    return {
        type: UPDATE_COUNTRY_FAILURE
    };
};

/**
 *
 * @param locale
 * @return {{type: *, locale: *}}
 */
export const changeLocale = (locale) => {
    return {
        type: CHANGE_LOCALE,
        locale: locale
    };
};


export const changeCountry = (newCountry) => {
    return {
        type: CHANGE_COUNTRY,
        newCountry: newCountry
    };
};

export const isChangedLocale = (isChanged) => {
    return {
        type: IS_CHANGED_LOCALE,
        localeChange: isChanged
    };
};