import {ANSWER_FAILURE, ANSWER_REQUEST, ANSWER_SUCCESS} from "../../../store/actionType";
import {updateObject} from "../../../util/updateObject";

const initialState = {
    answers: [],
    answerRequest: false
};

function setAnswerRequest(state) {
    return updateObject(state, {
        answerRequest: true
    });
}

function setAnswerSuccess(state, action) {
    let answers = action.data;
    answers.answerRequest = false;
    return updateObject(state, answers);
}

function setAnswerFailure(state) {
    return updateObject(state, {
        answers: [],
        answerRequest: false
    });
}

const answerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ANSWER_REQUEST:
            return setAnswerRequest(state);
        case ANSWER_SUCCESS:
            return setAnswerSuccess(state, action);
        case ANSWER_FAILURE:
            return setAnswerFailure(state);
        default:
            return state;
    }
};

export default answerReducer;
