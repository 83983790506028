import React, {Component} from 'react';
import FooterPage from "./FooterPage";
import HeaderPage from "./HeaderPage";
import Routes from "../../Routes";
import {connect} from "react-redux";
import {isChangedLocale} from "../../module/appearance/store/action";
import {loadLocales} from "../../util/loadLocales";

export class Layout extends Component {
    state = {initDone: false};
    componentDidMount() {
        this.loadLocales();
    }

    loadLocales() {
        loadLocales(this.props.currentLocale)
            .then(() => {
                // After loading CLDR locale data, start to render
                this.setState({initDone: true});
                this.props.finishChange(false);
            });
    }

    render() {

        return (
            this.state.initDone &&
            <div data-test="app-Div" className="flyout">
                <HeaderPage/>
                <main style={{ marginTop: "10rem" }}>
                    <Routes />
                </main>
                <FooterPage/>

            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        currentLocale: state.appearance.currentLocale, // TODO: determine locale here
        locales: state.appearance.locales,
        localeChange: state.appearance.localeChange,
        shop:state.session.shop
    }
}

const mapDispatchToProps = dispatch => {
    return {
        finishChange: (b) => dispatch(isChangedLocale(b))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);