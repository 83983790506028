import React, {Component} from 'react';
import './Message.css';

class ErrorMessage extends Component {

    render() {
        return (
            <div data-test="errormessage" className="errormessage">
                {this.props.children}
            </div>
        );
    }

}

export default ErrorMessage;