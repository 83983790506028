import React, {Component} from 'react';
import {connect} from "react-redux";

class Webshop extends Component {

    render() {
        let result;
        if (typeof this.props.shop !== 'undefined') {
            result = <a href={this.props.shopUrl}>
                {this.props.shop}
            </a>
        } else {
            result = <i>de webshop</i>
        }

        return (
            <strong>
                {result}
            </strong>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        shop: state.session.shop.name,
        shopUrl: state.session.shop.url
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

const LinkWebshop = connect(mapStateToProps, mapDispatchToProps)(Webshop);

export default LinkWebshop;