import React, {Component} from "react";
import intl from "react-intl-universal";
import OrderItems from "../component/OrderItems";
import {ORDER_DONE, ORDER_NEW, ORDER_SHIPPING} from "../util/progressState";
import GenButton from "../../../ui/button/GenButton";
import {download} from "../store/service";
import {connect} from "react-redux";
import "./SaveOrder.css";
import PropTypes from "prop-types";
import {retrieveAnswers} from "../../answer/store/service";


export class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            total: Number(0)
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }

    componentDidMount() {
        const items = [];
        let total = Number(0);
        for (const item of this.props.items) {
            items.push({
                id: item.id,
                sku: item.sku,
                name: item.name,
                delivered: item.delivered,
                returned: null == item.returned ? 0 : item.returned,
                reason: item.reason,
                reasonText: null == item.reasonText ? '' : item.reasonText
            });
            total = this.addItem(total, item);
        }
        this.setState({
            items: items,
            total: total
        });
        this.props.retrieveAnswers(this.props.orderProgressState, this.props.currentLocale);
    }

    handleChange(id, data) {
        let answerChoice = {};
        if("reason" === data.field) {
            answerChoice[0] = "";
            for(const answer of this.props.answers) {
                answerChoice[answer.id] = answer.text;
            }
        }

        const items = this.state.items.map((item) => {
            if (item.id !== id) {
                return item;
            } else {
                let update = {[data.field]: data.value};
                if(("returned" === data.field) && (0 === data.value)) {
                    update.reason = null;
                } else if("reason" === data.field) {
                    if (0 === data.value){
                        update.reason = null;
                    }else{
                        update.reasonText = answerChoice[data.value];
                    }

                }
                return {...item, ...update};
            }
        });
        let total = 0;
        for (const item of items) {
            total = this.addItem(total, item);
        }

        this.setState({
            items: items,
            total: total
        });
    }

    addItem(total, item) {
        const quantity = Number(item.returned);
        return (total + quantity);
    }

    handleDownload() {
        const order = {
            "orderNumber": this.props.orderNumber,
            "status": this.props.orderProgressState,
            "items": this.state.items
        };
        this.props.download(order);
    }

    getDownloadText() {
        let downloadText;
        if (ORDER_SHIPPING === this.props.orderProgressState || ORDER_DONE === this.props.orderProgressState) {
            downloadText = intl.get('save_order.download_text1')
        } else {
            downloadText = intl.get('save_order.download_text2');
        }
        return downloadText;
    }

    hasValidItems() {
        let content = false;
        let reasonFilled = true;
        for (const item of this.state.items) {
            const returned = item.returned > 0;
            content = content || returned;
            if(returned) {
                reasonFilled = reasonFilled && (null !== item.reason);
            }
        }
        return content && reasonFilled;
    }

    render() {
        const finished = ((ORDER_SHIPPING === this.props.orderProgressState) || (ORDER_DONE === this.props.orderProgressState));
        const disabled = this.props.disabled || (ORDER_NEW !== this.props.orderProgressState);
        const downloadText = this.getDownloadText();
        const downloadDisabled = this.props.disabled || !this.hasValidItems() || this.props.answerRequest;
        let msg = null;
            if (ORDER_DONE === this.props.orderProgressState) {
                msg = ( <div data-test="download-msg" className="download-msg">
                    <span>{intl.get('user_messages.messagem14')}</span>
                </div>)

        }
        return (
            <div data-test="save-order" className="save-order">
                <div data-test="items" id="items">
                    <OrderItems items={this.state.items}
                                total={this.state.total}
                                answers={this.props.answers}
                                onChange={this.handleChange}
                                finished={finished}
                                disabled={disabled}/>
                </div>
                {msg}

                <div data-test="download-button" id="download-button">
                    <GenButton text={downloadText}
                               onClick={this.handleDownload}
                               disabled={downloadDisabled}/>
                </div>
            </div>
        );
    }
}

OrderDetails.propTypes = {
    orderNumber: PropTypes.string,
    orderProgressState: PropTypes.string,
    items: PropTypes.array,
    answers: PropTypes.array,
    answerRequest: PropTypes.bool,
    disabled: PropTypes.bool,
    currentLocale: PropTypes.string,
    retrieveAnswers: PropTypes.func,
    download: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        orderNumber: state.orderReturn.orderNumber,
        orderProgressState: state.orderReturn.status,
        items: state.orderReturn.items,
        answers: state.qa.answers,
        answerRequest: state.qa.answerRequest,
        disabled: state.appearance.disabled,
        currentLocale: state.appearance.currentLocale
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveAnswers: (orderProgressState, locale) => dispatch(retrieveAnswers(orderProgressState, locale)),
        download: (order) => dispatch(download(order))
    };
};

const SaveOrder = connect(mapStateToProps, mapDispatchToProps)(OrderDetails);

export default SaveOrder;
