import React from "react";
import intl from "react-intl-universal";
import {MDBCol, MDBContainer, MDBRow, MDBFooter, MDBIcon} from "mdbreact";
import ed   from './thumbnail_retourenshop.png';
import ups   from './ups.svg';
import postNl   from './postnl-3-logo-png-transparent.png';
import './Footer.css';

const FooterPage = () => {
    let middleColumn = (
        <MDBCol md="2">
            <li data-test="logo" className="list-unstyled">
                <img style={{ height: "5.5rem" }} src={ed}/>
            </li>
            <li className="list-unstyled">
                &nbsp;&nbsp;
            </li>
            {/*<li className="list-unstyled">*/}
            {/*    <div data-test="adress" className="footerFont">Postbus 160</div>*/}
            {/*</li>*/}
            {/*<li className="list-unstyled">*/}
            {/*    <div data-test="adress" className="footerFont">3640 AD MIJDRECHT</div>*/}
            {/*</li>*/}
            {/*<li className="list-unstyled">*/}
            {/*    <div data-test="adress" className="footerFont">  <a href="mailto:retourenshop@minc.nl">retourenshop@minc.nl </a></div>*/}
            {/*</li>*/}
            {/*<li className="list-unstyled">*/}
            {/*    <div data-test="adress" className="footerFont">  Retouren.shop is powered by D-Liver Solutions.</div>*/}
            {/*</li>*/}
            {/*<li className="list-unstyled">*/}
            {/*</li>*/}
        </MDBCol>
    )
    // if(!props.isFrontPage){
    //     middleColumn = (
    //         <MDBCol md="2"></MDBCol>
    //     )
    // }
    return (
        <MDBFooter data-test="footerFont" color="#fafafa grey lighten-5" >

            <div className="footer-copyright text-center py-3 #f4511e deep-orange darken-1">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol md="4">

                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCol >
                            &copy; {new Date().getFullYear()} Copyright: <a href="mailto:retourenshop@minc.nl"> D-Liver Solutions B.V. </a>
                            </MDBCol>
                            <MDBCol >
                                <div data-test="adress" >Postbus 160 3640 AD MIJDRECHT</div>
                            </MDBCol>
                            <MDBCol >
                                <div data-test="adress" >  <a href="mailto:retourenshop@minc.nl">retourenshop@minc.nl </a></div>
                            </MDBCol>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBRow>
                            <MDBCol md="5">
                                <li data-test="logo" className="list-unstyled">
                                    <img style={{ height: "5.5rem" }} src={ups}/>
                                </li>
                                <li className="list-unstyled">
                                    &nbsp;&nbsp;
                                </li>
                            </MDBCol>
                            <MDBCol md="2">
                                <li data-test="logo" className="list-unstyled">
                                    <img style={{ height: "5.5rem" }} src={postNl}/>
                                </li>
                                <li className="list-unstyled">
                                    &nbsp;&nbsp;
                                </li>
                            </MDBCol>
                            </MDBRow>
                        </MDBCol>

                    </MDBRow>
                </MDBContainer>
            </div>

        </MDBFooter>
    );
}

export default FooterPage;