import axios from "axios";
import {
    changeCountry,
    changeLocale,
    isChangedLocale,
    updateCountryFailure,
    updateCountryRequest,
    updateCountrySuccess
} from "./action";
import {loadLocales} from "../../../util/loadLocales";
import {retrieveAnswers} from "../../answer/store/service";

/**
 * Returns Redux Thunk function that dispatches
 * `updateCountryRequest` action
 *  and `countryRequest` action
 * @param alias string
 * @param locale string
 * @returns {Function} - Redux Thunk function.
 */
export const retrieveCountry = (alias, locale) => {
    if (null !== alias) {
        return dispatch => {
            dispatch(updateCountryRequest(alias));
            dispatch(countryRequest(alias, locale));
        }
    }
};

export const changeLocaleApp = (params) => {
    const locale = llocaleChange(params.flagCod);
    loadLocales(locale);
    return dispatch => {
        dispatch(changeLocale(locale));
        dispatch(changeCountry(params.flagCod));
        dispatch(retrieveCountry(params.consumerCountry, locale));
        dispatch(retrieveAnswers(params.orderProgressState, locale));
        dispatch(isChangedLocale(!params.isChangingCheLanguage));
    }
};

/**
 * Returns Redux Thunk function that initiates an axios request
 *    and dispatches the response `updateCountrySuccess` as a success
 *    and dispatches the response `updateCountryFailure` as exception
 * @param alias string
 * @param locale string
 * @returns {Function} - Redux Thunk function.
 */
const countryRequest = (alias, locale) => {
    return dispatch => {
        axios.get("/api/country/find", {
            params: {"alias": alias, "locale": locale}
        }).then(response => {
            dispatch(updateCountrySuccess(response.data));
        }).catch(() => {
            dispatch(updateCountryFailure());
        });
    }
};

const llocaleChange = (countryCode) => {
    switch (countryCode) {
        case  "NL" :
            return "nl-NL";
        case  "EN" :
            return "en-UK";
        case  "GB" :
            return "en-UK";
        case  "BR" :
            return "pt-BR";
        case  "DE" :
            return "de-DE";
        case  "IT" :
            return "it-IT";
        case  "ES" :
            return "es-ES";
        case  "FR" :
            return "fr-FR";
        default:
            return "nl-NL";
    }
};