import React, {Component} from 'react';
import './GenButton.css';

class GenButton extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick();
    }

    render() {
        return (
            <div data-test="gen-btn" className="actionbutton">
                <button type="button" onClick={this.handleClick} disabled={this.props.disabled}>
                    {this.props.text}
                </button>
            </div>
        );
    }
}

export default GenButton;