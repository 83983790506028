import React, {Component} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import Layout from "./ui/layout/Layout";
// import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
// import Layout from "./ui/layout/Layout";
// import AutomaticLogin from "./module/login/container/AutomaticLogin";
// import ViewConsumer from "./module/order/container/ViewConsumer";
// import Home from "./module/home/component/Home";
// import { BrowserRouter as Router } from "react-router-dom";

class App extends Component {

    render() {

        return (

            <div data-test="app-Div" className="flyout">
                <Router>
                    <Layout/>
                </Router>
            </div>
        );
    }
}

export default App;