import axios from "axios";
import {downloadedLabel, orderRequestFailure, retrievedOrder} from "./action";
import {disableUI, enableUI} from "../../appearance/store/action";
import {ORDER_ERROR, ORDER_NEW} from "../util/progressState";
import {attach} from "../../../util/file";
import {retrieveCountry} from "../../appearance/store/service";

export const search = (order, params) => {
    return dispatch => {
        dispatch(disableUI());
        dispatch(searchOrderRequest(order, params));
    }
};

export const retrieveOrder = (params) => {
    return dispatch => {
        dispatch(disableUI());
        dispatch(getOrder(params));
    }
};

export const download = (order) => {
    return dispatch => {
        dispatch(disableUI());
        if(ORDER_NEW === order.status) {
            dispatch(saveOrderRequest(order));
        } else {
            dispatch(downloadRequest());
        }
    }
};

const searchOrderRequest = (order, params) => {
    return dispatch => {
        axios.post("/api/order", order
        ).then(
            handleRetrievedOrder(dispatch, params)
        ).catch(error => {
            dispatch(orderRequestFailure(error));
            dispatch(enableUI());
        });
    }
};

const getOrder = (params) => {
    return dispatch => {
        axios.get("/api/order"
        ).then(
            handleRetrievedOrder(dispatch, params)
        ).catch(error => {
            dispatch(enableUI());
        });
    }
};

const handleRetrievedOrder = (dispatch, params) => {
    return response => {
        dispatch(retrievedOrder(response.data));
        if (ORDER_ERROR !== response.data.status) {
            const isFirst = (ORDER_NEW === response.data.status);
            dispatch(enableUI({isFirst: isFirst}));
        }
        if((typeof response.data.country !== 'undefined') && (null !== response.data.country)) {
            dispatch(retrieveCountry(response.data.country, params.locale));
        }
    };
};

const saveOrderRequest = (order) => {
    return dispatch => {
        axios.put("/api/order", order
        ).then(response => {
            dispatch(downloadRequest());
        }).catch(error => {
            dispatch(orderRequestFailure(error));
            dispatch(enableUI());
        });
    }
};

const downloadRequest = () => {
    return dispatch => {
        axios.post("/api/shipment"
        ).then(response => {
            const data = response.data;
            dispatch(downloadedLabel(data));
            dispatch(enableUI({mailStatus: data.mailStatus}));
            attach(data.pdf, "application/pdf");
        }).catch(error => {
            dispatch(orderRequestFailure(error));
            dispatch(enableUI());
        });
    }
};
